<template>
    <div style="margin: 15px 5px; ">
        <div class="flex ai-center jc-center" style="height: 50px;">
            <svg style="margin: 0px 5px; width: 25px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" x="0px" y="0px">
                <path d="M17.5,10H8V7a4,4,0,0,1,8,0,.5.5,0,0,0,1,0A5,5,0,0,0,7,7v3H6.5A1.5,1.5,0,0,0,5,11.5v9A1.5,1.5,0,0,0,6.5,22h11A1.5,1.5,0,0,0,19,20.5v-9A1.5,1.5,0,0,0,17.5,10ZM18,20.5a.5.5,0,0,1-.5.5H6.5a.5.5,0,0,1-.5-.5v-9a.5.5,0,0,1,.5-.5h11a.5.5,0,0,1,.5.5ZM12,13a2,2,0,0,0-.5,3.93V18.5a.5.5,0,0,0,1,0V16.93A2,2,0,0,0,12,13Zm0,3a1,1,0,1,1,1-1A1,1,0,0,1,12,16Z"></path>
            </svg>
            <a href="#" v-on:click.prevent="onLinkClick" v-if="showLink">
                Have an access / promo code?
            </a>
            <transition name="fade">
                <form method="get" class="pure-form" v-on:submit="onSubmit" style="width: 100%" v-if="showInput">
                    <div class="pure-control-group flex ai-center jc-center" style="width: 100%;"  v-if="showInput">
                        <input id="access-key"
                               name="accessCode"
                               v-model="accessCode"
                               type="text"
                               maxlength="128"
                               autocomplete="none"
                               :readonly="busy"
                               required
                               style="width: 100%; margin-bottom: 0px;"
                               placeholder="Enter your access code"/>
                        <button type="submit" :disabled="isSubmitDisabled" class="pure-button" style="margin-left: 10px; width: 100px; font-weight: normal;">
                            <span v-if="!busy" >Unlock</span>
                            <span>
                                <i v-if="busy" class="icon icon-load-c-black spin"></i>
                            </span>
                        </button>
                    </div>
                </form>
             </transition>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                showInput: false,
                busy: false,
                accessCode: ''
            }
        },
        methods: {
            onLinkClick() {
                this.showInput = true
            },
            onSubmit() {
                this.busy = true
            }
        },
        computed: {
            showLink() {
                return this.showInput == false
            },
            isSubmitDisabled() {
                function isEmptyOrSpaces(str) {
                    return str == null || str.match(/^ *$/) !== null;
                }
                return isEmptyOrSpaces(this.accessCode) || this.busy === true;
            }
        }
    }
</script>