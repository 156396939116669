<template>
    <div>
        <div class="modal-backdrop fade in"></div>
        <form method="post" v-on:submit.prevent="onSubmitRemindMe">
            <div class="modal modal-info" id="myModal">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title" id="myModalLabel">
                                Remind Me About this Event
                            </h4>
                        </div>
                        <div class="modal-body" style="text-align: center;">
                            <div v-show="!userHasSubmittedSuccessfully">
                                <div v-if="!hasCustomerGuid">
                                    <p>
                                        <label>Enter your email: </label>
                                        <input id="email"
                                               name="email"
                                               v-model.trim="email"
                                               autocomplete="email"
                                               type="email"
                                               maxlength="255"
                                               placeholder="Enter your email address"
                                               required>
                                    </p>
                                    <p class="remind-me-text">
                                        Your email address will be added to the Oztix eNews Flash database to ensure you are reminded about this event and other similar events.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div v-show="userHasSubmittedSuccessfully">
                            <p>
                                Thank you, your email has been registered to receive updates for this event.
                            </p>
                        </div>
                        <div class="modal-footer">
                            <button class="pure-button pure-button-primary" type="submit" v-show="!userHasSubmittedSuccessfully">
                                <span>Remind Me</span>
                            </button>

                            <button v-on:click.prevent="closeModal" class="close-button">Close</button>
                        </div>
                        <div class="overlay" v-show="busy"></div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>
<script>
    import axios from 'axios'

    export default {

        props: {
            endpointUrl: {
                required: true,
                type: String,
            },
            eventId: {
                required: true,
                type: String
            },
            customerGuid: {
                type: String
            }
        },
        data: function () {
            
            return {
                userHasSubmittedSuccessfully: false,
                busy: false,
                hasCustomerGuid: (this.customerGuid != undefined && this.customerGuid.length > 0)
            }
        },
        methods: {
            closeModal() {
                this.$parent.closeRemindMeModal()
            },
            showBusy() {
                this.busy = true
            },
            hideBusy() {
                this.busy = false
            },
            onSubmitRemindMe() {

                Promise.resolve()
                    .then(this.showBusy())
                    .then(this.postRemindMe)
                    .then(res => {
                        this.showSuccessMessage(res)
                        this.hideBusy()
                    })
                    .catch(err => {
                        this.closeModal()
                        this.$parent.handleError(err)                        
                    })
            },
            showSuccessMessage(response) {                
                if (response.data) {
                    this.userHasSubmittedSuccessfully = true
                } else {
                    this.$parent.handleError('Error with marketing API.')
                }                
            },
            postRemindMe() {
                var remindMeEndpointUrl = this.endpointUrl + '/RemindMe'

                return axios.post(remindMeEndpointUrl, {
                    customerEmail: this.email,                    
                    eventGuid: this.eventId,
                    customerGuid: this.customerGuid,
                })
            },
        },
    }
</script>