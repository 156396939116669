
<template>
    <div id="waitlist-container" class="tickets">
        <div class="ticket-name-container" v-if="showWaitlistConfirmation">
            <div class="ticket active">
                <div>
                    <h3>Success</h3>
                    <p>
                        You've been added to the waitlist.
                    </p>
                    <p>
                        Should any more tickets become available, we will notify you.
                        Alternatively, you can check our <a v-bind:href="secondaryTicketingUrl" target="_blank">resale partner</a>
                        <a v-bind:href="secondaryTicketingUrl" target="_blank"><div class="tixelLogo"></div></a> for tickets.
                    </p>
                </div>
            </div>
        </div>

        <modal v-if="displayErrorModal" v-cloak>
            <template #title>Something went wrong</template>
            <template #body>
                <i class="icon icon-alert-circled icon-large"></i>
                <p>Oops, something went wrong</p>
            </template>
        </modal>

        <div v-if="showWaitlistForm">
            <form class="pure-form pure-form-aligned"
                v-bind:asp-action="apiUrl"
                method="post"
                v-on:submit.prevent="submit"
            >
                <div class="ticket-name-container">
                    <div class="ticket active">
                        <div>
                            <h3 style="display: block">
                                Tickets are unavailable
                            </h3>
                            <p class="waitlist-notickets-text">
                                There are currently no tickets available for reservation.
                                If you join the waiting list, you will be notified by email if more tickets become available or new shows are announced.
                                In doing so you agree your information may be provided to the band, promoter, venue and/or related parties.
                            </p>
                            <div class="pure-control-group">
                                <label for="email">E-mail</label>
                                <input id="email"
                                    name="email"
                                    v-model.trim="email"
                                    autocomplete="email"
                                    type="email"
                                    maxlength="255"
                                    placeholder="Enter your email address"
                                    required>
                            </div>
                        </div>
                    </div>
                </div>

                <button style="margin-top: 0px;" type="submit" class="pure-button pure-button-primary flex justify-center items-center gap-0.5" :disabled="busy">
                    <span>Join the Waitlist</span>
                    <i v-if="busy" class="icon icon-load-c spin"></i>
                </button>

                <div class="overlay" v-show="busy"></div>

            </form>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import { trackWaitlist } from '../../Shared/analytics'

export default {
    data() {
        return {
            showWaitlistForm: true,
            showWaitlistConfirmation: false,
            email: '',
            displayErrorModal: false,
            busy: false,
        }
    },
    props: {
        waitlistUrl: {
            required: true,
            type: String,
        },
        secondaryTicketingUrl: {
            required: true,
            type: String,
        },
    },
    methods: {
        openWaitlistConfirmation() {
            this.showWaitlistForm = false
            this.showWaitlistConfirmation = true
        },

        displayError(exception) {
            this.displayErrorModal = true
        },

        showBusy() {
            this.busy = true
        },

        hideBusy() {
            this.busy = false
        },

        submit() {
            return Promise.resolve()
                .then(this.showBusy)
                .then(this.postWaitlist)
                .then(this.openWaitlistConfirmation)
                .then(() => trackWaitlist(this.email))
                .catch(this.displayError)
                .then(this.hideBusy)
        },

        postWaitlist() {
            return axios.post(this.waitlistUrl, {
                email: this.email,
            })
        },
    },
}
</script>