<template>
    <div id="registration-container" class="tickets">
        <div class="ticket-name-container" v-if="showRegistrationConfirmation">
            <div class="ticket active">
                <div>
                    <h3>Success</h3>
                    <p>You will be notified as soon as this event goes on sale.</p>
                </div>
            </div>
        </div>

        <modal v-if="displayErrorModal" v-cloak>
            <template #title>Something went wrong</template>
            <template #body>
                <i class="icon icon-alert-circled icon-large"></i>
                <p>The e-mail address you entered is not valid. Please try again.</p>
            </template>
        </modal>

        <div v-if="showRegistrationForm">
            <form class="pure-form pure-form-aligned"
                method="post" :action="registrationUrl"
                v-on:submit.prevent="submit"
            >
                <div class="ticket-name-container">
                    <div class="ticket active">
                        <div>
                            <p class="waitlist-notickets-text">
                                Register your interest to be notified when tickets become available. In doing so you agree your information may be provided to the band, promoter, venue and/or related parties
                            </p>
                            <div class="pure-control-group" style="text-align: center;">
                                <input id="email"
                                    name="email"
                                    v-model.trim="email"
                                    autocomplete="email"
                                    type="email"
                                    maxlength="255"
                                    placeholder="Enter your email address"
                                    required>
                            </div>
                        </div>
                    </div>
                </div>

                <button style="text-transform: inherit; margin-top: 0px;" type="submit" class="pure-button pure-button-primary flex justify-center items-center gap-0.5" :disabled="busy">
                    <span>NOTIFY ME</span>
                    <i v-if="busy" class="icon icon-load-c spin"></i>
                </button>

                <div class="overlay" v-show="busy"></div>

            </form>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import { trackRegistration } from '../../Shared/analytics'

export default {
    data() {
        return {
            showRegistrationForm: true,
            showRegistrationConfirmation: false,
            email: '',
            displayErrorModal: false,
            busy: false,
        }
    },
    props: {
        registrationUrl: {
            required: true,
            type: String,
        },
    },
    methods: {
        openRegistrationConfirmation() {
            this.showRegistrationForm = false
            this.showRegistrationConfirmation = true
        },

        displayError(exception) {
            this.displayErrorModal = true
        },

        showBusy() {
            this.busy = true
        },

        hideBusy() {
            this.busy = false
        },

        submit() {
            return Promise.resolve()
                .then(this.showBusy)
                .then(this.postRegistration)
                .then(this.openRegistrationConfirmation)
                .then(() => trackRegistration(this.email))
                .catch(this.displayError)
                .then(this.hideBusy)
        },

        postRegistration() {
            return axios.post(this.registrationUrl, {
                email: this.email,
            })
        },
    },
}
</script>
