<template>
    <div class="ticket active">
        <div>
            <h3>We are currently experiencing high demand, this page will automatically refresh in {{secondsLeft}} seconds.</h3>
            <p>Please be patient and wait for the countdown to complete
            and the page to refresh. When a place becomes available you will
            have the opportunity to purchase tickets if they are still available.</p>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            elapsedSeconds: 0,
            timeoutId: null
        }
    },
    props: {
        secondsToThrottle: {
            required: true,
            type: Number,
        },
    },
    methods: {
        initialize() {
            this.timeoutId = setInterval(() => {
                this.elapsedSeconds = this.elapsedSeconds + 1
                if (this.elapsedSeconds >= this.secondsToThrottle) {
                    clearInterval(this.timeoutId)
                    window.location.reload(true)
                }
            }, 1000)
        }
    },
    computed: {
        secondsLeft() {
            return this.secondsToThrottle - this.elapsedSeconds
        }
    },
    mounted() {
        this.initialize()
    }
}
</script>