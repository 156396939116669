/* HACK: This manipulates the Event Page DOM to render collapsible panels (hidey showy)
   where tickets have ticket headers defined.
*/

export function injectHideyShowyHack(vueVm) {
    document.body.addEventListener("application-ready", function (e) {
        try {
            function injectStyles() {
                //CSS
                const styleSheet = document.createElement("style")
                styleSheet.textContent = `
                .pure-button-primary {
                    margin-top: 25px !important;
                }
                
                .ticket-header-collapsible {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    position: relative;
                    padding: 7px 2.5em 7px 10px;
                    cursor: pointer;
                    border: none;
                    border-radius: 2px;
                    text-align: left;
                    outline: none;
                    font-weight: 600;
                    font-size: medium;
                    margin-bottom: 0.3em;
                    margin-top: 0.3em;
                    user-select: none;
                }
                
                .ticket-header-collapsible.closed {
                    background-color: #004D8B;
                    color: white;
                    box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    -webkit-box-sizing: border-box;
                }
                
                .ticket-header-collapsible.opened {
                    background-color: #FF7545;
                    color:#fff;
                }
                
                .ticket-header-collapsible:hover {
                    background-color: #010c34;
                    color: #fff;
                }
                
                .ticket-header-collapsible:focus, .ticket-header-collapsible.opened:focus {
                    background-color: #FF7545;
                    color:#fff;
                }
                
                .ticket-hide {
                    display: none;
                }
                
                .ticket-header-collapsible:after {
                    content:"❯";
                    width: 2em;
                    text-align: center;
                    position: absolute;
                    right: 3px;
                    transition: all 0.35s;
                }
                
                .ticket-header-collapsible.opened:after  {
                    transform: rotate(90deg);
                }
            `;
                document.head.appendChild(styleSheet);
            }

            // Toggle specified class on all elements containing class provided
            function toggleClassOnElements(selector, toggleClass, force = false) {
                document.querySelectorAll(selector + ':not(#registration-container ' + selector + ')').forEach((el) => {
                    el.classList.toggle(toggleClass, force);
                });
            }

            // Get the elements between specified element and element with specified selector
            let getElementsUntil = function (elem, selector) {
                let siblings = [];
                let nextElement = elem.nextElementSibling;

                // Loop through all siblings
                while (nextElement) {

                    // If the matching item is found, bail
                    if (selector && nextElement.matches(selector)) break;

                    // Otherwise, push to array
                    siblings.push(nextElement);

                    // Get the next sibling
                    nextElement = nextElement.nextElementSibling
                }
                return siblings;
            };

            function setupHeadings() {
                document.querySelectorAll('.ticket-header').forEach((el, i) => {
                    // set a unique id for each heading
                    let headingId = 'heading-' + i;
                    el.setAttribute('id', headingId);
                    el.setAttribute('role', 'button');
                    el.tabIndex = 0;
                    el.classList.add('ticket-header-collapsible', 'closed');

                    el.addEventListener('click', function (event) {
                        let hideTickets;

                        // toggle heading class from opened/closed
                        if (el.classList.contains('closed')) {
                            el.classList.replace('closed', 'opened');
                            hideTickets = false;
                        } else {
                            el.classList.replace('opened', 'closed');
                            hideTickets = true;
                        }

                        // show or hide the tickets based on headingid
                        toggleClassOnElements('.' + headingId, 'ticket-hide', hideTickets);
                    });

                    // Handle keyboard events for accessibility
                    el.addEventListener('keydown', function (e) {
                        const keyD = e.key !== undefined ? e.key : e.keyCode;

                        if ((keyD === 'Enter' || keyD === 13) || (['Spacebar', ' '].indexOf(keyD) >= 0 || keyD === 32)) {
                            // In IE11 and lower, e.key will equal "Spacebar" instead of ' '

                            // Default behavior is prevented to prevent the page to scroll when "space" is pressed
                            e.preventDefault();
                            this.click();
                        }
                    });
                });
            }

            function addTicketGrouping() {
                let headingCount = document.querySelectorAll('.ticket-header-collapsible').length;

                for (let j = 0; j < headingCount; j++) {
                    let headingId = 'heading-' + j;
                    let nextHeadingId = '#heading-' + (j + 1);
                    let tickets = getElementsUntil(document.getElementById(headingId), nextHeadingId);
                    if (tickets != null) {
                        tickets.forEach((el) => {
                            el.classList.add(headingId, 'ticket-hide');
                        });
                    }
                }
            }

            function objectIsEmpty(obj) {
                for (let prop in obj) {
                    if (obj.hasOwnProperty(prop)) {
                        return false;
                    }
                }
                return JSON.stringify(obj) === JSON.stringify({});
            }

            // Don't inject hidey showy if there are no tickets
            let inventory = vueVm.$data.inventory;
            if (objectIsEmpty(inventory)) {
                return;
            }

            injectStyles();
            setupHeadings();
            addTicketGrouping();
        } catch (e) {
            console.log("Hidey Showy initialisation failed: " + e);
        }
    });
}